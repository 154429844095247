import Vue from 'vue'
import App from './page/App.vue'
import veform from '@verified/veform'
import { validateNumberNordic} from './validations'
import { validateNumberNordicMax} from './validations'
import { validateNumberNordicMax150} from './validations'
import { validateNumberNordicMin500} from './validations'
import { validateClearingNumber} from './validations'
import { validateAccountNumber} from './validations'

import Stepper from './components/stepper'
import Checkbox from './components/checkbox'
import Radio from './components/radio'
import Textarea from './components/textarea'

Vue.config.productionTip = false

import "./page/style.scss"

veform.addCustomValidation('nordic_number', validateNumberNordic)
veform.addCustomValidation('nordic_numbermax', validateNumberNordicMax)
veform.addCustomValidation('nordic_numbermax150', validateNumberNordicMax150)
veform.addCustomValidation('nordic_numbermin500', validateNumberNordicMin500)
veform.addCustomValidation('clearing-number', validateClearingNumber)
veform.addCustomValidation('account-number', validateAccountNumber)


Vue.use(veform)
Vue.use(Stepper)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Textarea)

new Vue({
    render: h => h(App),
}).$mount('#app')