<template>
    <div>
        <div v-for="(item, id) in options" :key="id" :class="['custom-control', { 'custom-control-inline': inline }, 'custom-radio', 'mb-1']">
            <input type="radio" class="custom-control-input" :value="value" :checked="value[id]" @change="handleInput" :id="'ve-radio-' + id" :disabled="disabled">
            <label class="custom-control-label custom-radio-label" :for="'ve-radio-' + id">{{ item.label }}</label>
             <button v-if="item.tip" type="button" data-toggle="tooltip"
            :class="item.tip['tip-position']=='left' ? 'tooltip-left' : 'tooltip-right'"  
            :data-offset="item.tip.offset||0"
            :data-placement="item.tip['text-placement']||'top'"
            :data-trigger="item.tip.trigger||'hover focus'"
            :data-html="item.tip.html ? 'true' : 'false'"
            :title="__(item.tip.text||item.tip.html)||item.tip.text||item.tip.html"></button>
        </div>
        <div v-show="validation_error" class="custom-invalid-feedback">{{ __("validation.radio")}}</div>
    </div>
</template>

<script>

export default {
    name: "ve-custom-radio",
    props: {
        disabled: {
        type: Boolean,
        default: false
        },
        value: {
            type: Object,
            default: () => { return {} }
        },
        options: {
            type: Object,
            default: {}
        },
        rules: {
            type: String,
            default: ""
        },
        inline: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        for(let option in this.options){
            if(this.options[option].tip) {
                /** 
                 * Removing sanitize is a workaround for it to be compatible with IE. 
                 * Source: https://stackoverflow.com/questions/56462877/popover-angular-bootstrap-4-3-failure
                 */
                $('[data-toggle="tooltip"]').tooltip({
                    sanitize: false, sanitizeFn: content => content
                })
            }
        }
            
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
        handleInput(e) {

            let id = e.srcElement.id.split('ve-radio-')[1]

            for(let key in this.value)
                if(key === id)
                    this.value[key] = true
                else 
                    this.value[key] = false



            this.$emit('input', this.value)
            this.$emit('radio-click', true)

            this.performValidation()
            this.$forceUpdate()
        },
        performValidation() {
            if(this.rules.includes('required')) {
                let ok = false

                for(let key in this.value)
                    if(this.value[key]) ok = true

                for(let key in this.value) {
                    if(!ok) {
                        document.getElementById(`ve-radio-${key}`).setCustomValidity("Select one of the radiobuttons")
                        this.validation_error = true
                    } else {
                        document.getElementById(`ve-radio-${key}`).setCustomValidity("")
                        this.validation_error = false
                    }  
                }
            }
        }
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.performValidation(val)
            }
        }
    },
    created() {
        for(let option in this.options)
            if(!this.value[option])
                this.value[option] = false
        this.$emit('input', this.value)

        this.$nextTick(() => {
            this.performValidation()
        })
    }
}
</script>

<style lang="scss" scoped>


</style>
