export const validateNumberNordic = (value, constraint) => {
    if(value && !isNaN(value.replace(/\s/g,'').replace(',','.'))) {
        return { status: true }
    }
    return { status: false, key: "validation.number" }

}
export const validateNumberNordicMax = (value, constraint) => {
    if(value && !isNaN(value.replace(/\s/g,'').replace(',','.')) && value.replace(/\s/g,'').replace(',','.') <= 1000) {
        
        return { status: true }
    }
    return { status: false, key: "validation.maxnumber" }

}
export const validateNumberNordicMax150 = (value, constraint) => {
    if(value && !isNaN(value.replace(/\s/g,'').replace(',','.')) && value.replace(/\s/g,'').replace(',','.') <= 150000) {
        
        return { status: true }
    }
    return { status: false, key: "validation_maxnumber150" }

}
export const validateNumberNordicMax250 = (value, constraint) => {
    if(value && !isNaN(value.replace(/\s/g,'').replace(',','.')) && value.replace(/\s/g,'').replace(',','.') <= 250000 && value.replace(/\s/g,'').replace(',','.') >= 10000) {
        
        return { status: true }
    }
    return { status: false, key: "validation_maxnumber250" }

}
export const validateNumberNordicMin500 = (value, constraint) => {
    if(value && !isNaN(value.replace(/\s/g,'').replace(',','.')) && value.replace(/\s/g,'').replace(',','.') >= 500) {
        
        return { status: true }
    }
    return { status: false, key: "validation_min500" }

}
export const validateClearingNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        let regex = new RegExp(rule.replace(/#/g,'|'))
        if(regex.exec(value)){
            return {status: true}
        }
    }

    return { status: false, key: `validation.clearingnumber.${message}` }    
}
export const validateAccountNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }
    return { status: false, key: `validation.accountnumber.${message}` }    
}