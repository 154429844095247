import axios from 'axios'
import Datepicker from 'vuejs-datepicker';
import {sv, en} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import { auth, envelopes, bisnode } from "@verified/libvf"

export default {
  name: "app",
  data() {
    return {
			busy: false,
			loading: true,
			submitted: false,
			merging: false,
			envelope: null,
			bisnodeInProgress: false,
			lang: "sv_SE",
			role: "owner",
			config: null,
			sv:sv,
			enableForwarding: false,
			showInvalidFeedback: {
				"datepicker-emp-start" : false,
				"datepicker-emp-end" : false,
			},
			bgImage: "supp",
			ud: {
				companyName: "Ekonomiskt stödpaket",
				_isForwarded: true,
				shouldForward: {
					"yes" : false
				},
				fileName: null,
				redirectUrl: "https://www.rodakorset.se/",
				translations: null,
				lang: "sv_SE",
				today: moment().format('YYYY-MM-DD'),
				estimatedRevenue: null,
				contribution: null,
				secondhand: null,
				totalIncome: null,
				totalExpense: null,
				loanType:{
					shortTerm:false,
					contribution:false
				},
				servicePersonal: {
					firstName: null,
					lastName: null
				},
				boardDesition: {
					yes:false,
					no:false
				},
				turnOverQstn: {
					turnOverYes:false,
					turnOverNo:false
				},
				turnOverQstn1: {
					turnOverYes1:false,
					turnOverNo1:false
				},
				decision: {
					admitted: false,
					rejected: false,
					refused: false
				},
				chairman: {
					firstName: null,
					lastName: null,
					email: null,
					phone: null
				},
				treasurer: {
					firstName: null,
					lastName: null,
					email: null,
					phone: null
				},
				signerInfo: {
					firstName: null,
					lastName: null,
					email: null,
					phone: null
				},
				approverInfo: {
					firstName: null,
					lastName: null,
					email: null,
					phone: null
				}
			}
		}
	},
	methods: {
		getUrlParameter(param, dummyPath) {
			var sPageURL = dummyPath || window.location.search.substring(1),
				sURLVariables = sPageURL.split(/[&||?]/),
				res;
			for (var i = 0; i < sURLVariables.length; i += 1) {
				var paramName = sURLVariables[i],
					sParameterName = (paramName || '').split('=');
				if (sParameterName[0] === param) {
					res = sParameterName[1];
				}
			}
			return res;
		},
		calculateTotalIncome(){
			this.ud.totalIncome=(Number(this.ud.estimatedRevenue ? this.ud.estimatedRevenue : 0)+Number(this.ud.secondhand ? this.ud.secondhand : 0)+Number(this.ud.contribution ? this.ud.contribution : 0)).toFixed(0);
		},
		calculateTotalExpense(){
			this.ud.totalExpense=(Number(this.ud.personalCost ? this.ud.personalCost : 0)+Number(this.ud.rentalCost ? this.ud.rentalCost : 0)+Number(this.ud.otherExpense ? this.ud.otherExpense : 0)).toFixed(0);
		},
		validateForm(form) {
			return this.$refs[form].validate()
		},
		async fetchBisnode(){
			if(this.bisnodeInProgress || !this.ud.employee.ssn || this.ud.employee.ssn.length < 13){
				return
			}
			this.bisnodeInProgress = true
			try{
				this.busy = true
				this.isBisnodeFetched = false
				let person = await bisnode.sweden.getPersonInfo(this.ud.employee.ssn.replace("-",""))
				let user = person.consumer
				this.ud.employee.firstName = user.firstName
				this.ud.employee.lastName = user.surname
				this.ud.employee.zip = user.postCode.replace(' ','')
				this.ud.employee.address = user.postalAdressLine1
				this.ud.employee.city = user.town

			}catch(ex){
				// console.log(ex)
			}
			this.bisnodeInProgress = false
			this.busy = false

		},
		async copyData(source) {
			this.ud.signerInfo.firstName = this.ud[source].firstName;
			this.ud.signerInfo.lastName = this.ud[source].lastName;
			this.ud.signerInfo.email = this.ud[source].email;
			this.ud.signerInfo.phone = this.ud[source].phone;
		},
		async submit() {
			if(!this.validateForm('form1')){
				return
			}
			this.loading = true
			
			if(this.role === 'owner'){
				console.log("Owner submit")
				this.submitOwner()
			}else{
				this.submitEditor()
			}

		},
		async submitOwner(){
			let envelope = null
			let self = this
			this.fileName = `Ekonomiskt stödpaket`
			this.ud.expectedDateStr = moment(this.ud.expectedDate).format('YYYY-MM-DD')
			this.ud.dateDesitionStr = moment(this.ud.dateDesition).format('YYYY-MM-DD')
			this.ud.capitalApplicationDateStr = moment(this.ud.capitalApplicationDate).format('YYYY-MM-DD')
			await envelopes.create('redcross-support').then(function (res) {
				envelope = res;
			}).then(function () {
				return envelope.getBearerToken('/flows/simple-public-flow');
			}).then(function (token) {
				return auth.setToken(token);
			}).then(function (env) {
				return envelope.reflect();
			}).then(function(){
				envelope.firstTemplate().setUserData(self.ud)
			}).then(function () {
				return self.addFirstRecipients(envelope)
			}).then(function () {
				return envelope.firstDocument().put({ name: self.fileName })
			}).then(function () {
				return envelope.publish();
			}).then(function () {
				self.sent = true
				self.loading = false
				self.submitted=true;
				//window.location.href = self.ud.redirectUrl;
			}).catch(function (err) {
				console.log("ERROR", err)
				self.loading = false
				throw err
			});
		},
		async submitEditor(){
			let envelope = this.envelope
			let self = this
			self.ud._isForwarded = self.ud.shouldForward.yes
			envelope.reflect()
			.then(function(){
				return self.addSecondRecipient(envelope)
			}).then(function(){
				return envelope.firstTemplate().setUserData(self.ud)
			}).then(function(){
				if(!self.ud.shouldForward.yes){
					return envelope.getSignToken("/flows/simple-public-flow")
					.then(function(token){
						self.signUrl = "https://app.verified.eu/#/sign"+ envelope.data.uid + "?access_token=" + token + "&lang=" + self.lang + "&redirect_to=" + encodeURIComponent(self.ud.redirectUrl);
						localStorage.setItem(envelope.data.id, self.signUrl);
						console.log("Got sign URL:", self.signUrl);
						window.location.href = self.signUrl;
					});
				}else{
					window.location.href = self.ud.redirectUrl
				}

			}).catch(function(err){
				console.log("ERROR", err)
				self.loading = false
				throw err
			})

		},
		async init(){
			var source    = this.getUrlParameter('source')||'original';
			let uri = window.location.search.substring(1);
    		let params = new URLSearchParams(uri)
			let docUid = params.get("document_uid")
			if(docUid){
				//editor / signer
				let self = this
				await auth.useTokenFromUrl()
				await auth.getUserinfo().then(u => {
					u.roles.forEach(r => {
						if(r.name){
							let role = r.name.split('/').pop();
							if (role === 'editor' || role === 'redcross-editor'){
								self.role = role
							}
						}
					});
				})
				this.envelope = await envelopes.getFromUrl()
				console.log("Role", this.role)
				if(this.role === 'redcross-editor' && this.config.redirectSign && this.config.redirectSign[source]){
					this.ud.redirectUrl = this.config.redirectSign[source]
				}
		
				let data = await this.envelope.firstTemplate().getUserData();
				this.merging = true
				this.ud = { ...this.ud, ...data }

				if (localStorage.getItem(this.envelope.data.id)) {
					this.signUrl = localStorage.getItem(this.envelope.id);
					console.log(this.signUrl);
					window.location.href = this.signUrl;
					this.loading = false;
					this.$forceUpdate();
				}
				setTimeout(function() { this.merging = false}, 1000)
			}else{
				this.role = "owner"
				let authOpts = {token:this.config.token[source], namespace:this.config.namespace[source]}
				auth.authenticate(authOpts)
				if(this.config.redirectEdit && this.config.redirectEdit[source]){
					this.ud.redirectUrl = this.config.redirectEdit[source]
				}		
			}
		},
		addFirstRecipients(envelope){
			let promises = []
			var source    = this.getUrlParameter('source')||'original';
			promises.push(
				envelope.addRecipient({
				"givenName": this.config.firstName[source],
				"familyName": this.config.lastName[source],
				"email": this.config.email[source],
				"language": "sv_SE",
				"signingMethod": "email",
				"order": 1,
				"role": {
					"action": "edit",
					"label": "Fylla i formuläret som Röda Korset",
					"name": "redcross-editor"
				}
			}))

			// promises.push(
			// 	envelope.addRecipient({
			// 	"givenName": this.config.firstName[source],
			// 	"familyName": this.config.lastName[source],
			// 	"email": this.config.email[source],
			// 	"language": "sv_SE",
			// 	"signingMethod": "email",
			// 	"order": 2,
			// 	"role": {
			// 		"action": "review",
			// 		"label": "Godkänna som Röda Korset",
			// 		"name": "review"
			// 	}
			// }))
			
			promises.push(
				envelope.addRecipient({
					"givenName":this.ud.signerInfo.firstName,
					"familyName": this.ud.signerInfo.lastName,
					"email": this.ud.signerInfo.email,
					"language": "sv_SE",
					"signingMethod": "email",
					"order": 3,
					"role": {
						"action": "sign",
						"label": "Signera dokumentet",
						"name": "signer"
					}
			}))
			return Promise.all(promises)
		},
		addSecondRecipient(envelope){
			//Add Approver
			var source    = this.getUrlParameter('source')||'original';

			return envelope.addRecipient({
				"givenName": this.ud.shouldForward.yes ? this.ud.approverInfo.firstName : this.config.firstName[source],
				"familyName": this.ud.shouldForward.yes ? this.ud.approverInfo.lastName : this.config.lastName[source],
				"email": this.ud.shouldForward.yes ? this.ud.approverInfo.email : this.config.email[source],
				"language": "sv_SE",
				"signingMethod": "email",
				"order": 2,
				"role": {
					"action": "review",
					"label": "Godkänna som Röda Korset",
					"name": "review"
				}
			})
		},

	},
	computed: {

	},
	watch:{
		"ud.estimatedRevenue" : function(newVal,oldVal) {
			this.calculateTotalIncome();
		},
		"ud.secondhand" : function(newVal,oldVal) {
			this.calculateTotalIncome();
		},
		"ud.contribution" : function(newVal,oldVal) {
			this.calculateTotalIncome();
		},
		"ud.personalCost" : function(newVal,oldVal) {
			this.calculateTotalExpense();
		},
		"ud.rentalCost" : function(newVal,oldVal) {
			this.calculateTotalExpense();
		},
		"ud.otherExpense" : function(newVal,oldVal) {
			this.calculateTotalExpense();
		}
	},
	async created() {

		const [translations, config] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/redcross-support/translations'),
			axios.get('https://sheets.web-services.verified.eu/redcross-support/config')
		])
		
		var source    = this.getUrlParameter('source')||'original';
		this.mergeLocale(translations.data)
		this.ud.translations = translations.data
		this.config = config.data
		
		// Set language to use
		this.setIso(this.config.language[source])
		let cover = document.getElementById("cover");
		if(this.config){
			cover.style.backgroundImage = `url(${this.config.background[source]})`
		}
		await this.init();
		this.loading = false
		
	},
	components: {
		Datepicker
	},
}
